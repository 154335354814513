(function () {
  'use strict'

  /**
  * The Google Management Tag ID
  * @type {String}
  * @see https://support.google.com/tagmanager/answer/7582054
  */
  const GTM_ID = 'GTM-NTNPS8G'

  /**
   * The name of the cookie to set
   */
  const cookieName = 'seen_cookie_message'

  /**
   * Sets or gets a cookie
   * @param {String} name - the name of the cookie
   * @param {String} value - the value of the cookie
   * @param {Object} options - the options for the cookie
   * @returns Boolean|String - null if the cookie is not found, otherwise the cookie value
   */
  const cookie = function (name, value, options) {
    if (typeof value !== 'undefined') {
      if (value === false || value === null) {
        return setCookie(name, '', { days: -1 })
      } else {
        return setCookie(name, value, options)
      }
    } else {
      return getCookie(name)
    }
  }

  /**
   * Sets a cookie with the given name and options to the given value
   * @param {String} name - the name of the cookie
   * @param {String} value - the value of the cookie
   * @param {Object} options - the options for the cookie
   * @returns void
   */
  const setCookie = function (name, value, options) {
    options = options || {}

    let cookieString = name + '=' + value + '; path=/'
    if (options.days) {
      const date = new Date()
      date.setTime(date.getTime() + options.days * 24 * 60 * 60 * 1000)
      cookieString = cookieString + '; expires=' + date.toGMTString()
    }
    if (document.location.protocol === 'https:') {
      cookieString = cookieString + '; Secure'
    }
    cookieString = cookieString + '; SameSite=Strict'
    document.cookie = cookieString
  }

  /**
   * Get the requested cookie
   * @param {String} name - the name of the cookie
   * @returns Boolean|String - null if the cookie is not found, otherwise the cookie value
   */
  const getCookie = function (name) {
    const nameEQ = name + '='
    const cookies = document.cookie.split(';')
    for (let i = 0, len = cookies.length; i < len; i++) {
      let cookie = cookies[i]
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length)
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return decodeURIComponent(cookie.substring(nameEQ.length))
      }
    }
    return null
  }

  /**
   * Add a click event to the cookie message to set the cookie
   * @returns void
   */
  const clickToAcceptCookieMessage = function () {
    const button = document.querySelector('.cc_btn_accept_all')
    button.addEventListener('click', function () {
      const banner = document.getElementById('global-cookie-message')
      banner.classList.add('cookie-banner--hidden')
      cookie(cookieName, 'yes', { days: 28 })
      checkForConsent()
    })
  }

  /**
   * Add the cookie message to the page
   * @returns void
   */
  const addCookieBanner = function () {
    const banner = document.getElementById('global-cookie-message')
    const showCookieBanner = banner && cookie(cookieName) === null

    if (showCookieBanner) {
      banner.classList.remove('cookie-banner--hidden')
    }
  }

  /**
   * Checks if the cookie has been set and if so, loads the gtm library,
   * establishes GTM_ID as the default property ID, and sends a start event to
   * Google Analytics.
   */
  const checkForConsent = function () {
    const consent = cookie(cookieName) === 'yes'
    if (consent) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        })

        // Google Tag Manager Addition
        const f = d.getElementsByTagName(s)[0]
        const j = d.createElement(s)
        const dl = l !== 'dataLayer' ? '&l=' + l : ''

        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl

        const o = d.createComment(' Google Tag Manager ')
        const c = d.createComment(' End Google Tag Manager ')

        f.parentNode.insertBefore(j, f)
        f.parentNode.insertBefore(o, j)
        f.parentNode.insertBefore(c, j.nextSibling)
      })(window, document, 'script', 'dataLayer', GTM_ID)
    }
  }

  /**
   * Necessary in case Modernizr is not present
   * Adds a class of 'js' to the html element
   * @returns void
  */
  const addJavaScriptAvailable = function () {
    const root = document.documentElement
    const cls = root.className
    root.className = cls ? cls + ' js' : 'js'
  }

  /**
   * Checks if the browser is IE
   * @returns Boolean - true if the browser is IE, false otherwise
   */
  const isIE = function () {
    return !(
      !document.attachEvent || typeof document.attachEvent === 'undefined'
    )
  }

  /**
   * Run the callback when the DOM is ready
   * @param {Function} callback
   * @returns callback
   */
  const domReady = function (callback) {
    if (isIE()) {
      document.attachEvent('onreadystatechange', function () {
        if (document.readyState === 'complete') {
          return callback()
        }
      })
    } else {
      document.addEventListener('DOMContentLoaded', function () {
        return callback()
      })
    }
  }

  /**
   * Run the functions when the DOM is ready
   */
  domReady(function () {
    addJavaScriptAvailable()
    checkForConsent()
    addCookieBanner()
    clickToAcceptCookieMessage()
  })
}.call(window))
